<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <el-button type="primary" @click="on_pop_addplus()" icon="el-icon-plus"
          >新建</el-button
        >
        <el-collapse accordion>
          <el-collapse-item v-for="item in List_Data" :key="item.id">
            <template slot="title">
              <div class="title-sty">
                <p>
                  {{ item.name }}
                </p>
                <div>
                  <el-button
                    @click.stop="on_pop_addplus(item.id)"
                    icon="el-icon-edit"
                    >编辑</el-button
                  >
                  <el-button
                    @click.stop="on_delete(item.id)"
                    icon="el-icon-close"
                    >删除</el-button
                  >
                </div>
              </div>
            </template>

            <div v-if="item.item.length == 0">暂无内容</div>
            <div class="option" v-else>
              <div v-for="el in item.item" :key="el.id">
                {{ el.name }}
                <i @click="on_pop_addplus(el.id)" class="el-icon-edit"></i>
                <i @click="on_delete(el.id)" class="el-icon-circle-close"></i>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <!-- 新建修改弹窗 -->
        <el-dialog
          :title="title"
         show-close
          :visible.sync="dialog_show"
          center
        >
          <AuthorityRedact
            v-if="dialog_show"
            ref="AuthorityRedact"
            :refbool.sync="dialog_show"
            @way_url="way_url"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorityRedact from "../../components/view_redact/authority_redact.vue";
import { powerindex, powerdelete } from "../../assets/request/api";
export default {
  name: "authority_management",
  components: { AuthorityRedact },
  data() {
    return {
      title: "",
      dialog_show: false,
      List_Data: [],
    };
  },
  created() {
    this.way_url();
  },
  methods: {
    way_url() {
      powerindex().then((res) => {
        this.List_Data = res.data;
        console.log(res.data);
      });
    },
    on_delete(id) {
      let that = this;
      this.$confirm("是否确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          powerdelete({ id: id }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              that.way_url();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.AuthorityRedact.way_show(id);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.option {
  display: flex;
  flex-wrap: wrap;
  div {
    padding: 5px 8px;
    border: 1px solid #d9ecff;
    margin: 0 8px 8px 0;
    background: #ecf5ff;
    color: #409eff;
    border-radius: 4px;
  }
  i {
    cursor: pointer;
    margin: 0 8px 8px 0;
  }
}
.title-sty {
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    margin-right: 20px;
  }
}
</style>
