<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item label="上级：">
          <el-cascader
            :value="cascader_pid"
            :show-all-levels="false"
            :options="powers_List"
            :props="{
              checkStrictly: true,
              label: 'name',
              children: 'item',
              value: 'id',
            }"
            @change="dataChange"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="名称："
          prop="name"
          :rules="[{ required: true, message: '请选择是否显示' }]"
        >
          <el-input
            placeholder="请输入名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="链接："
          prop="url"
          :rules="[{ required: true, message: '请输入链接' }]"
        >
          <el-input
            placeholder="请输入链接"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.url"
          ></el-input>
        </el-form-item>
        <el-form-item label="图标类：">
          <el-input
            placeholder="请输入图标类"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.icon"
          ></el-input>
        </el-form-item>

        <el-form-item label="控制器：">
          <el-input
            placeholder="请输入控制器"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.control"
          ></el-input>
        </el-form-item>
        <el-form-item label="方法：">
          <el-input
            placeholder="请输入方法"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.action"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input
            type="number"
            placeholder="请输入排序"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.sort"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="是否菜单："
          prop="is_show"
          :rules="[{ required: true, message: '请选择是否菜单' }]"
        >
          <el-radio
            v-for="item in show_list"
            v-model="formLabelAlign.is_show"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary"
          >保存</el-button
        >
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { powersaveInfo, powersave } from "../../assets/request/api";

export default {
  name: "authority_redact",
  data() {
    return {
      show_list: [],
      powers_List: [],
      cascader_pid: "",
      formLabelAlign: {
        name: "",
        url: "",
        is_show: "",
        icon: "",
        pid: "",
        icon: "",
        control: "",
        action: "",
        sort:"",
      },
    };
  },
  methods: {
    dataChange(event) {
      this.cascader_pid = event;
      let events = event[event.length - 1];
      this.formLabelAlign.pid = events;
    },
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      this.id = id;
      powersaveInfo(froms).then((res) => {
        let { show_list, info, powers } = res.data;
        this.powers_List = powers;
        console.log(this.powers_List);
        this.show_list = show_list;
        if (id) {
          let { formLabelAlign } = this;
          let { name, is_show, url, pid, icon, action, control ,sort} = info;
          formLabelAlign.name = name;
          formLabelAlign.url = url;
          formLabelAlign.is_show = is_show;
          formLabelAlign.icon = icon;
          formLabelAlign.action = action;
          formLabelAlign.control = control;
          formLabelAlign.sort = sort;

          
          if (pid) {
            formLabelAlign.pid = pid;
            this.cascader_pid = pid;
          }
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id, cascader_pid } = this;
          if (id) {
            formLabelAlign.id = id;
          }
          if (!cascader_pid || cascader_pid.length == 0) {
            formLabelAlign.pid = 0;
          }
          powersave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_url");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}

/deep/ .el-tree-node.is-current > .el-tree-node__content {
  color: #ffffff;
  background: #4f7afd !important;
  padding: 0 10px;
}
/* 点击后的当前节点的子节点的背景颜色 */
/deep/ .el-tree > .el-tree-node.is-current {
  // background: #1f2d3d;
}
/* 鼠标悬浮的当前节点  */
/deep/ .el-tree-node__content:hover {
  background: #f8f8f9;
  color: rgb(64, 158, 255);
}
</style>